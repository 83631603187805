@font-face {
  font-family: "Light";   /*Can be any text*/
  src: local("Poppins-Light"),
    url("./fonts/Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Medium";   /*Can be any text*/
  src: local("Poppins-Medium"),
    url("./fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Regular";   /*Can be any text*/
  src: local("Poppins-Regular"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SemiBold";   /*Can be any text*/
  src: local("Poppins-SemiBold"),
    url("./fonts/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Bold";   /*Can be any text*/
  src: local("Poppins-Bold"),
    url("./fonts/Poppins-Bold.ttf") format("truetype");
}
