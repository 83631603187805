.contactPageSection1Container{
    background-color: #F4F6F5;
    margin: 0px 100px;
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    padding: 4% 0%;
}
.Section1Col1{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 7%;
}
.contactPageHeader1{
    font-size: 30px;
    font-family: "Bold";
    font-weight: 600;
    margin-bottom: 20px;
}
.contactPageContent{
    font-size: 16px;
    font-family: "Regular";
    font-weight: 500;
    margin-bottom: 20px;
}
.workshopPrice{
    font-size: 14px;
    font-family: "Regular";
    font-weight: 400;
    margin-bottom: 5px;
    opacity: 0.8;
    margin-top: 15px;
}
.section1col2{
    width: 50%;
    display: flex;
    align-self: center;
    justify-content: center;
}
.optionTradingImg{
    height: 30vh;
    width: auto;
}
.contactPageSection2{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1% 0%;
}
.contactPageHeader2{
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    font-family: "SemiBold";
    color: #01996D;
    margin-bottom: 20px;
}
.workshopHighlightsCard{
    border: 1px solid #01996D;
    border-radius: 20px;
    padding: 20px;
    width: 40%;
}
.workshopHighlights{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}
.highlightsContent{
    font-size: 16px;
    font-weight: 500;
    font-family: "Regular";
    color: #000000; 
    margin-left: 15px;
}
.contactPageSection3{
    background-color: #EBEEEB;
    padding: 2%;
}
.workshopOverviewCardsSection{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 2% 0%;
}
.workshopOverviewCard1{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: white;
    z-index: 2;
    box-shadow: 5px 8px 10px rgb(233, 231, 231);
    border-radius: 10px;
    width: 20%;
}
.workshopOverviewsteps{
    background-color: #01996D;
    border-radius: 10px;
    border: none;
    padding: 7px 15px;
    font-size: 25px;
    font-weight: 600;
    font-family: "SemiBold";
    color: white;
    z-index: 2;
    text-align: center;
    box-shadow: 5px 8px 10px rgb(233, 231, 231);
}
.workshopOverviewHeader{
    font-size: 18px;
    font-weight: 600;
    font-family: "SemiBold";
    margin: 10px;
    text-align: center;
}
.workshopOverviewContent{
    font-size: 14px;
    font-weight: 500;
    font-family: "regular";
    margin: 10px 0px 20px 0px;
    text-align: center;
    width: 70%;
}
.timingSection{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #EBEBEB;
    padding: 10px 20px;
    border-radius: 10px;
    margin-top: auto;
}
.WorkshopOverviewTimings{
    font-size: 14px;
    font-weight: 500;
    font-family: "Regular";
    margin-left: 10px;
}

.registerFormContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3%;
}
.registerFormheading{
    font-size: 40px;
    font-weight: 600;
    font-family: "Bold";
    margin-bottom: 30px;
    color: #01996D;
}
.registerForm{
    width: 30%;
}
.registerFormUsername{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.registerFormlabel{
    font-size: 14px;
    font-weight: 400;
    font-family: "Medium";
}
input{
    width: 100%;
    height: 20px;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid black;
    margin: 6px 0px 15px 0px;
}
.registerBtn{
    border: none;
    background-color: #01996D;
    color: white;
    font-size: 18px;
    font-weight: 500;
    font-family: "SemiBold";
    padding: 10px 25px;
    border-radius: 10px;
    margin-top: 20px;
}
.workshopDetailsContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.workshop1Card{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding: 15px;
    background-color: white;
    z-index: 2;
    box-shadow: 5px 8px 10px rgb(233, 231, 231);
    border-radius: 10px;
    width: 42%;
}
.workshop1CardHeader{
    font-size: 16px;
    font-weight: 500;
    font-family: "Medium";
    margin-bottom: 10px;
    text-align: left;
    color: #01996D;
}

.workshop1CardContent{
    font-size: 12px;
    font-weight: 500;
    font-family: "Regular";
    margin-bottom: 10px;
    text-align: left;

}
.QRImg{
    height:450px;
    width: 100%;
}
