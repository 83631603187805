.contactPageImg{
    width: 100%;
    height: 80vh;
    object-fit: cover;
    background-repeat: no-repeat;
    filter: brightness(.8);
}
.contactPageSection2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 3% 0%;
}
.contactPageHeader{
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    font-family: "Bold";
    color: #01996D;
    margin-bottom: 2%;
    
}
.contactCards{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    
    /* padding: 0% 3%; */
}
.contactCard{
    border:1px solid #01996D;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    padding: 2% ;
    margin-left: 20px;
}
.contactCardsContent{
    font-size: 16px;
    font-weight: 500;
    font-family: "Regular";
    margin: 10px 0px;
    text-align: center;
}
.contactPageSection3{
    background-color: #EBEEEB;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3% 0%;
}
.contactPageSection3Header{
    font-size: 35px;
    font-weight: 600;
    font-family: "SemiBold";
    width: 40%;
    margin-bottom: 20px;
    text-align: center;
}
.contactFormContainerText{
    background-color: white;
    width: auto;
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 20px;
    z-index: 2;
    box-shadow: 5px 5px 5px rgb(65, 64, 64);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2%;
}
.heading{
    font-size: 25px;
    font-weight: 600;
    font-family: "Bold";
    color: #000000;
    margin: 15px 10px;
}
.submitBtn{
    border: none;
    background-color: #01996D;
    color: white;
    font-size: 15px;
    font-weight: 500;
    font-family: "Medium";
    padding: 10px 30px;
    border-radius: 10px;
    margin: 20px 10px;
}
.contactForm{
    width: 90%;
}
.contactFormUsername{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.error{
    color: red;
    font-size: 14px;
    font-family: "Regular";
    margin-top: -15px;
}

/* The container */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    font-family: "Medium";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #01996D;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
       top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  }

  button{
    cursor: pointer;
  }
