.homePageSection1{
    display: flex;
    flex-direction: column;
}
.homeContainerText {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-family: "Bold";

}

.homeBgImg {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    background-repeat: no-repeat;
    filter: brightness(.8);
}
.secureAdvTagline{
    font-size: 40px;
    font-weight: 600;
    font-family: "Bold";
    color: white;
    text-align: center;
}
.homePageBtn{
    border: none;
    background-color: #fff;
    color: #01996D;
    padding: 15px 30px;
    font-size: 16px;
    font-weight: 600;
    font-family: "SemiBold";
    border-radius: 10px;
    margin-top: 30px;
    margin-left: 35%;
}

.homePageSection2{
    display: flex;
    flex-direction: row;
}
.contentContainer{
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 40px 100px;
}
.HomePageHeading{
    font-size: 35px;
    font-weight: 600;
    font-family: "SemiBold";
    color: #01996D;
}
.content{
    font-size: 16px;
    font-weight: 500;
    font-family: "Regular";
    color: #000000;
    margin-top: 15px;
}
.cardContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.card{
    width: auto;
    background-color: #fff;
    border-radius: 20px;
    padding: 25px;
    margin-top: -30%;
    z-index: 2;
    box-shadow: 5px 8px 10px rgb(233, 231, 231);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.cardHeader{
    font-size: 18px;
    font-weight: 600;
    font-family: "Medium";
    color: #000000;
    text-align: center;
    margin: 15px;
}
.cardContent{
    font-size: 15px;
    font-weight: 400;
    font-family: "Regular";
    color: #000000;
    text-align: center;
}
.cardBtn{
    border: none;
    background-color: #01996D;
    color: white;
    font-size: 16px;
    font-weight: 600;
    font-family: "SemiBold";
    padding: 10px 20px;
    border-radius: 10px;
    margin-top: 15px;
    width: auto;
}
.link{
    text-decoration: none;
    color: #01996D;
}
.homePageSection3{
    display: flex;
    flex-direction: column;
    padding: 60px;
}
.header{
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    font-family: "Bold";
    color: #01996D;
    margin-top: 50px;
}
.cardsSection{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
}
.card1Img{
    width: auto;
    height: 250px;
}
.card1{
    width: 25%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
}
.card1Header{
    font-size: 18px;
    font-weight: 600;
    font-family: "SemiBold";
    color: #000000;
    margin: 15px 0px;
}
.card1Content{
    font-size: 15px;
    font-weight: 400;
    font-family: "Regular";
    color: #000000;
    width: 95%;
}
.homePageSection4{
    display: flex;
    flex-direction: column;
    padding: 60px;
    background-color: #EBEEEB;
}
.section4Card1Img{
    height: 70px;
    width: 70px;
}
.section4Header{
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    font-family: "Bold";
    color: #000000;
}
.section4Card1{
    width: 20%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background-color: #fff;
    padding: 40px 20px;
}
.section4Card1Header{
    font-size: 18px;
    font-weight: 600;
    font-family: "SemiBold";
    color: #000000;
    margin: 15px 0px;
    
}
.section4Card1SubHeader{
    font-size: 14px;
    font-weight: 400;
    font-family: "Regular";
    color: #000000;
    opacity: 70%;
    width: 80%;
    text-align: center;
}
.section4Card1Content{
    font-size: 15px;
    font-weight: 400;
    font-family: "Regular";
    color: #000000;
    width: 75%;
    margin-top: 15px;
    text-align: center;
   }